var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('CRow',[_c('CCol',{attrs:{"lg":"3"}},[_c('div',{staticClass:"form-group"},[_c('CInput',{attrs:{"placeholder":"Year Covered","type":"Number"},model:{value:(_vm.yearCovered),callback:function ($$v) {_vm.yearCovered=$$v},expression:"yearCovered"}})],1)]),_c('CCol',{attrs:{"lg":"2"}},[_c('CInput',{attrs:{"placeholder":"Processed By"},model:{value:(_vm.created_by),callback:function ($$v) {_vm.created_by=$$v},expression:"created_by"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"label":"type","options":[
            	{
            		type: '1st Half',
            		id: '1'
            	},
            	{
            		type: '2nd Half',
            		id: '2'
            	} ],"reduce":function (item) { return item.id; },"placeholder":"Type"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('CCol',{attrs:{"lg":"2"}}),_c('CCol',{staticClass:"float-right",attrs:{"lg":"2"}},[_c('CButton',{attrs:{"size":"sm","block":"","color":"info"},on:{"click":_vm.search}},[_c('i',{staticClass:"icon-magnifier"}),_vm._v(" Search")])],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"2"}}),_c('CCol',{attrs:{"lg":"2"}}),_c('CCol',{attrs:{"lg":"2"}}),_c('CCol',{attrs:{"lg":"2"}}),_c('CCol',{attrs:{"lg":"2"}}),_c('CCol',{staticClass:"float-right",attrs:{"lg":"2"}},[_c('CButton',{attrs:{"size":"sm","block":"","color":"info"},on:{"click":_vm.clearSearch}},[_vm._v("Clear Search")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }