<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<!-- <h5>13th Month List({{dataList.total}})</h5>-->
					<h5>13th Month ({{dataList.total}})</h5>
			</CCol>
	     		 <CCol lg="6">
					  <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
						v-if="config.getPermission('13th_month').create"
						size="sm" style="margin-right: 10px" 
						color="success"  @click="addModal()" 
						class="float-lg-right"><i class="fa fa-plus"></i>Process 13th Month
					</CButton> 

					<CButton 
						v-if="config.getPermission('13th_month').download"
						size="sm" style="margin-right: 10px" 
						color="btn btn-outline-primary"  @click="download()" 
						class="float-lg-right"><i class="fa fa-download"></i> Download
					</CButton> 
						 

			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="!showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @adjustment-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
	<hr style="margin-bottom: 0px;margin-top: 0px">
		<CRow>	
				
	      
		<CCol lg="12">  
			<div class="table-responsive-sm table-responsive-md">
	      		<CDataTable
				   :loading="isLoading"
			      	:items="dataList.data"
			      	:fields="fields"
					hover
					outlined
					pagination
					:itemsPerPage="10"
					itemsPerPageSelect
					
			    >  
			    	<template #action="{item}">
				        <td style="padding: 5px; width: 100px">
				        	<CDropdown 
				                toggler-text="Select"  
								size="sm"
				                color="info"
				            >
								
				            	<CDropdownItem v-if="config.getPermission('13th_month').view" @click="viewModal(item)"><i class="fa fa-eye" >View</i></CDropdownItem>  
				            	<!-- <CDropdownItem v-if="config.getPermission('furnishing').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o" > Update </i></CDropdownItem>    -->
				            	<CDropdownItem v-if="config.getPermission('13th_month').delete" @click="deleteItem(item)"><i class="fa fa-trash" > Delete</i></CDropdownItem>   
				            </CDropdown>
				        </td>
			      	</template> 
			    </CDataTable> 
			    <!-- <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        />  -->
			</div>

			
	      	</CCol>
	    </CRow> 
		</CCard>
<!-- VIEW -->
		<CModal :title="title" :show.sync="formModal" @close="closeModal()" color="info" size="md">
			
           	<form @submit.prevent="generate"> 	
		
			<CRow>
				<CCol>
					<form>
					<div class="form-group">
						<label for="email">Year Covered <span class="text-danger">*</span></label>
						<select class="form-control" v-model="yearCovered">
							<option></option>
							<option v-for="index in 10" :value="2019 + index" :key="index">{{2019 + index}}</option>
							
						</select>
					</div>
					<div class="form-group">
						<label for="pwd">Type</label>
						<select class="form-control" v-model="type">
							<option value="1" selected>1st Half</option>
							<option value="2">2nd Half</option>							
						</select>
					</div>
					</form>
				</CCol>
			</CRow>
			
	            <div slot="footer" class="w-100 mt-3">
					<!-- to avoid enabling button via inspect element -->
					<template v-if="!is_disabled">
	            	<CButton size="sm" type="submit" style="border-radius: .2rem; color: white;" v-if="is_valid" color="info" class="ml-1 mr-1 float-right">
	                    Generate
	                </CButton>
					<CButton size="sm" v-else type="button" style="border-radius: .2rem; color: white;" disabled color="info" class="ml-1 mr-1 float-right">
	                    Generate
	                </CButton>
					</template>
	                <CButton size="sm" style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="formModal = false">
	                    Cancel
	                </CButton>
	                
	            </div>
          	</form>
           	<div slot="footer" class="w-100"></div>
        </CModal>

		
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css'; 
import moment from 'moment';
import Search from './search';

export default {	
	mounted(){  
		this.getData();  	  
	},
	filters: {
		formatDate(val) {
			if(val) 
			return moment.unix(val).format('MM/DD/YYYY')
			return '';
		},
		uppercase(val) {
			if(val)
				return val.toUpperCase();
			return ''
		}
	},
	watch: {
		
		formModal(val) {
			if(!val) {				
				this.yearCovered = null;				
				this.type = '1';
			}
		}
	},
	computed: {
		is_valid() {
			return this.yearCovered ? true : false
		},
		is_disabled() {
			return this.mode.view;
		}
	},
	data(){
		return{
			limit:10,
			isLoading: false,
			config,
			dataParams: {
				
			},
			
			formModal: false,
			
			dataList: [],  
	     	depotName: "", 
			fields: [
				{
					key: 'created_at_f', 
					label: 'Created Date'
				},
				{
					key: 'year_covered', 
					label: 'Year Covered'
				}, 
				{
					key: 'type_f', 
					label: 'Type'
				},
				{
					key: 'processed_by', 
					label: 'Processed by'
				},
				{
					key: 'overall_total_f', 
					label: 'Total'
				},
				
				{
					key: 'action', 
					label: 'Action'
				}
			],
			currentPage: 1,  
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			
			filter: {
				yearCovered:"",					
				type:"",
				created_by:"",
			},
			
			
			showViewModal : false,
			history : [],

			mode : {
				view: false,
				new: true,
				edit: false
			},
			yearCovered : moment().format('YYYY'),
			type : '1'
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect},
	methods: {
		download() {
			this.$showLoading(true);
	    	axios.get(config.api_path+'/thirteenth-month/summary/report',{
					params : {
						year_covered: this.filter.yearCovered,					
						type: this.filter.type,
						created_by: this.filter.created_by,
					}					
				}
			)
	    	.then(response => {
				this.$showLoading(false);
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 

					setTimeout(() => {
						axios.get(`${config.api_path}/remove-file`,{
							params : {
								filename : response.data.file
							}
						})	
					}, 2000);
	    	})
			.catch(err => {
				console.error(err)
				this.$showLoading(false);
			})
			.finally(fin => {
				console.log(fin)
				this.$showLoading(false);
			})
		},
		generate() {
			let start_date = null, end_date = null;
			if(this.type == 1) { //first half = december to may
				start_date = moment(`12/01/${parseInt(this.yearCovered) - 1}`).startOf('day').unix();
				end_date = moment(`05/31/${this.yearCovered}`).startOf('day').unix();
			}
			else {
				start_date = moment(`06/01/${this.yearCovered}`).startOf('day').unix();
				end_date = moment(`11/30/${this.yearCovered}`).startOf('day').unix();
			}

			this.$showLoading(true)
			axios.get(config.api_path+'/thirteenth-month/generate',{
				params: {
					start_date,
					end_date,
					type : this.type,
					year : this.yearCovered
				}
			}).then(res => {
				this.$showLoading(false)
				this.formModal = false;				
				const {status} = res.data;

				if(status == 'success') {
					Swal.fire({
						title: 'Success!',
						text: "13th month generated successfully.",
						icon: 'success', 
					})
					this.getData();
				}
				else {
					Swal.fire({
						title: 'Error!',
						text: res.data.message,
						icon: 'error', 
					})
				}
			}).catch(eerr => {
				this.$showLoading(false)				
			})
			
		},
	
	addModal(){
		this.setMode('new')
		this.formModal = !this.formModal;
		this.title = "Process 13th Month";
		this.resetParam()
	},
	
	
	setMode(type) {
		this.mode = {
			view: false,
			edit : false,
			new : false
		}
		this.mode[type] = true;
	},
	
	
	viewModal(item){ 
		 window.open(`#/main/view_13th_month/${item.id}/${item.year_covered}/${item.type}`, '_blank');
	    }, 

	closeTransfer() {
		this.formModal = false;
	},



	closeModal() {
		console.log('oyet')
	},
		reloadData(){
			this.getData();  
		},
		// updatePage(data){
		// 	this.currentPage = data;
		// 	this.getData();
		// },   

	    search(event){
	    	this.filter = event; 
	    	this.getData(); 			 
	    }, 
		
	    getData(){  			
			this.isLoading = true;
	    	axios.get(config.api_path+'/thirteenth-month/list',{
					params : {
						year_covered: this.filter.yearCovered,					
						type: this.filter.type,
						created_by: this.filter.created_by,
					}					
				}
			)
	    	.then(response => {
	    		this.dataList = response.data;
	    		 this.dataList.data = this.dataList.data.map(item => {
					item.created_at_f = item.created_at ? moment(item.created_at).format('MM/DD/YYYY') : '';
					item.overall_total_f = item.overall_total ? this.$options.filters.number_format(item.overall_total, 'currency', 2) : '';
					item.type_f = item.type == 1 ? '1st Half' : '2nd Half';
					return item;
				 });
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;
			})
	    },		
	    
	  
  	getPersonnel(){

	    	axios.get(config.api_path+'/reference/personnel-list')
	    	.then(response => {
	    		this.personnelList = response.data;
	    	
	    	})

	    },

	
		 deleteItem(item){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to delete this 13th month ?', 
				showCancelButton: true,
				confirmButtonText: `Yes,delete`, 
				confirmButtonColor : 'red',
        		reverseButtons: true,
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+`/thirteenth-month/delete/${item.id}`)
			    	.then(response => {
			    		this.getData(); 
			    		Swal.fire({
							title: 'Success!',
							text: "13th month successfully deleted",
							icon: 'success', 
						})
			    	}) 
				}
			})  
	    }},
			


}
</script>
